<template>
  <Popup
    title="멤버십 구매취소"
    :maxWidth="500"
    closeBtnText="닫기"
    compeleteBtnText="구매취소"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete',cancelData)">
    <TableView v-if="data.cancel_at">
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col/>
        <col style="width:140px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">이전 구매취소 정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>멤버십 구매취소 환불금액</th>
          <td>{{ data.cancel_amount }}</td>
          <th>멤버십 구매취소일시</th>
          <td>{{ data.cancel_at | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col/>
      </template>
      <template v-slot:tbl_top v-if="data.cancel_at">
        <div class="area_left">
          <strong class="tit_tbl">멤버십 구매취소</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>멤버십 구매취소 환불금액</th>
          <td>
            <Input
              placeholder="환불금액 기록용"
              valueType="numberComma"
              :isBlockUnValueType="true"
              :value.sync="cancelData.cancel_amount"/>
            <p class="desc_info">💡 실제 환불되는 금액이 아닌 기록용입니다</p>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import { convertDateFormat, dateDiff } from "@lemontree-ai/lemontree-admin-common-front/utils/dateUtils";

export default {
  name:'MembershipPaymentCancelPopup',
  props:{
    data: Object
  },
  components:{
    Popup,
    TableView,
    Input,
  },
  computed:{
    isEndDate(){
      let today = convertDateFormat(new Date(), 'YYYY-MM-DD');
      const passDay = dateDiff(today,this.data.membership_end_at) > 0;
      const passDayColor = passDay ? 'tc_red' : '';
      return passDayColor
    }
  },
  data(){
    return{
      cancelData : {
        cancel_amount:null,
        cancel_at:''
      }
    }
  },
}
</script>
<!-- <style scoped>
</style> -->